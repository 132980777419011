<template>
  <div
    class="card-container"
    @click="onClick($event)"
  >
    <div
      v-if="!!$slots.cardIcon"
      class="card-icon"
    >
      <div class="left">
        <slot name="cardIcon" />
        <p class="card-icon-text">
          <!-- @slot Use this slot to place the card text -->
          <slot> Text </slot>
        </p>
      </div>
      <div
        v-if="windowWidth <= 960"
        class="right"
      >
        <img
          :src="CardArrow"
          alt="Card arrow"
          class="card-arrow"
        >
      </div>
    </div>
    <div
      v-if="!$slots.cardIcon"
      class="basic-card"
    >
      <p class="basic-card-text">
        <!-- @slot Use this slot to place the card text -->
        <slot> Text </slot>
      </p>
      <img
        :src="CardArrow"
        alt="Card arrow"
        class="card-arrow"
      >
    </div>
  </div>
</template>

<script>
import CardArrow from '@/assets/icons/chevron_right.svg'

export default {
  name: 'CustomCard',
  props: {
    creationData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      CardArrow,
      windowWidth: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    onClick ($event) {
      /**
       * Triggered when card is clicked.
       * Allows to use component without .native
       *
       * @event click
       * @property {event}
       * @type {object}
       */
      this.$emit('click', $event)
    },
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
div.card-container {
  background-color: $primary-white;
  cursor: pointer;
  width: 100%;

  div.card-icon {
    display: flex;
    flex-direction: column;
    width: 359px;
    height: 168px;
    padding: 32px;

    p.card-icon-text {
      margin: 32px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $primary-black;
    }
  }

  div.basic-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 358.67px;
    height: 128px;
    padding: 32px;

    p.basic-card-text {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $primary-black;
    }

    .card-arrow {
      margin-left: 16px;
    }
  }
}

@media screen and (max-width: $mobile) {
  div.card-container {
    div.card-icon {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 72px;
      padding: 24px;

      div.left {
        display: flex;
        align-items: center;

        p.card-icon-text {
          margin: 0 0 0 16px;
          font-size: 16px;
          letter-spacing: 0.5px;
        }
      }

      div.right {
        display: flex;
        align-items: center;
      }
    }

    div.basic-card {
      width: 100%;
      height: 72px;
      padding: 24px;

      p.card-icon-text {
        margin: 0 0 0 16px;
        font-size: 16px;
        letter-spacing: 0.5px;
      }
    }
  }
}
</style>
